import React from 'react';
import GPT4OForm from './GPT4OForm';

function App() {
  return (
    <div className="App">
      <h1>GPT-4O Architecture Assistant</h1>
      <GPT4OForm />
    </div>
  );
}

export default App;
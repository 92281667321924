import React, { useState } from 'react';
import axios from 'axios';


const GPT4OForm = () => {
  const [inputText, setInputText] = useState('');
  const [response, setResponse] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const handleImageUpload = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = '';
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        imageUrl = reader.result;
        await sendRequest(imageUrl);
      };
      reader.readAsDataURL(imageFile);
    } else {
      await sendRequest();
    }
  };

  const sendRequest = async (imageUrl = '') => {
    try {
      const response = await axios.post('https://api.thearchitectureapp.com/api/gpt4o', {
        inputText,
        imageUrl,
      });
     // console.log(response)
      setResponse(response.data.response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmitStream = async (e) => {
    e.preventDefault();

    let imageUrl = '';
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        imageUrl = reader.result;
        await sendStreamRequest(imageUrl);
      };
      reader.readAsDataURL(imageFile);
    } else {
      await sendStreamRequest();
    }
  };

  const sendStreamRequest = async (imageUrl = '') => {
    try {
      const response = await axios.post('https://api.thearchitectureapp.com/api/gpt4oStream', {
        inputText,
        imageUrl,
      }, {
        onDownloadProgress: (progressEvent) => {
          console.log('progressEvent:',progressEvent)
          const responseData = progressEvent.event.target.response;
          console.log('ResponseData:',responseData)
         // setResponse((prevResponse) => prevResponse + responseData);
          setResponse(responseData);
        },
        responseType: 'text',
      });
      console.log(response);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div>
      <form onSubmit={handleSubmitStream}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter a building description"
        ></textarea>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        <button type="submit">Submit</button>
      </form>
      <p>{response}</p>
    </div>
  );
};

export default GPT4OForm;